
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import Head from 'next/head';
import Script from 'next/script';
import type { TypePropsGetLayout } from '@/components/Layout';
import type { TypeHeaderProps } from '@/molecules/header';
import type { TypeFooterProps } from '@/organisms/footer';
import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import type { ReactElement, ReactNode } from 'react';
import '@/styles/globals.css';
import { ErrorBoundary } from '@/molecules';
import { ErrorPage } from '@/templates';
// eslint-disable-next-line @typescript-eslint/ban-types
type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
    getLayout?: ({ children, header, footer }: TypePropsGetLayout) => ReactNode;
};
type AppPropsWithLayout<T> = AppProps<T> & {
    Component: NextPageWithLayout;
};
export type AppCommonProps = {
    seo: {
        title?: string;
        metaDesc?: string;
        metaKeywords?: string;
        keywords?: string;
        canonical?: string;
        metaRobotsNoindex?: string;
        metaRobotsNofollow?: string;
    };
    header: TypeHeaderProps;
    footer: TypeFooterProps;
};
const App = ({ Component, pageProps: { seo, header, footer, ...others }, }: AppPropsWithLayout<AppCommonProps>) => {
    const getLayout = Component.getLayout
        ? (children: ReactElement) => Component.getLayout &&
            Component.getLayout({ children: children, header, footer })
        : (children: ReactElement) => children;
    let canonicalFinal;
    if (seo?.canonical) {
        const reg = /\/es\/$/;
        canonicalFinal = seo?.canonical?.replace('https://cmseditor.becasaessential.com/', 'https://www.becasaessential.com/');
        if (reg.test(seo?.canonical)) {
            canonicalFinal = canonicalFinal.replace(reg, '/');
        }
        else {
            canonicalFinal = canonicalFinal.slice(0, -1);
        }
    }
    return (<>
      <ErrorBoundary fallbackComponent={<ErrorPage statusCode={500}/>}>
        <Head>
          {seo?.title && <title>{seo.title}</title>}
          {seo?.metaDesc && <meta name="description" content={seo.metaDesc}/>}
          {(seo?.metaRobotsNoindex || seo?.metaRobotsNofollow) && (<meta name="robots" content={`${seo?.metaRobotsNoindex},${seo?.metaRobotsNofollow}`}/>)}
          {seo?.canonical && (<link rel="canonical" href={canonicalFinal ?? ''}/>)}
        </Head>
        <Script id="google-tag-manager-denied-consent" data-nscript="ageterInteractive">
          {`
                window.dataLayer = window.dataLayer || []; 
                function gtag(){dataLayer.push(arguments);} 
                // Default ad_storage to 'denied'. 
                gtag('consent', 'default', { 
                  ad_storage: "denied",
                  analytics_storage: "denied",
                  ad_user_data: "denied",
                  ad_personalization: "denied",
                  wait_for_update: 2000
                }); 
              `}
        </Script>

        {getLayout(<Component {...others}/>)}

        {process.env.GTM_ID && (<>
            <Script id="google-tag-manager" strategy="afterInteractive">
              {`
                (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','${process.env.GTM_ID}');
              `}
            </Script>
          </>)}

        {process.env.COOKIE_LAW_ID && (<>
            <Script src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js" data-domain-script={process.env.COOKIE_LAW_ID} strategy="beforeInteractive" integrity="sha384-//AdZA+I9PTsLsRnS48anpLivqqKIvFM9Gig/pz73KnBh0TGtq3p0FoM1Nfur4pa" crossOrigin="anonymous"/>

            <Script id="cookie_view" 
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onLoad={() => { }} strategy="beforeInteractive">
              {'function OptanonWrapper() {}'}
            </Script>
          </>)}
      </ErrorBoundary>
    </>);
};
const __Next_Translate__Page__1932bd8d0ea__ = App;

    export default __appWithI18n(__Next_Translate__Page__1932bd8d0ea__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  